import React from 'react'
import { OutboundLink } from 'gatsby-plugin-google-gtag'
import LocalizedLink from '../../LocalizedLink/LocalizedLink'

interface LaunchpadLinksProps {
  links: Array<{
    title: string
    url: {
      url: string;
    }
  }> | []
}

const LaunchPadLinks: React.FC<LaunchpadLinksProps> = ({links}) => {
  const buttonLink = (link: any) => {
    if (link.url.url.includes('http') || link.url.url.includes('https')) {
      return (
        <OutboundLink
          href={link.url.url}
          target="_blank"
          rel="noreferrer"
          className={`border btn--lg btn-block btn--${link.color} my-2`}
      >
        {link.title}
      </OutboundLink>
    )} else {
      return (
        <LocalizedLink to={link.url.url} className={`border btn--lg btn-block btn--${link.color}  my-2`}>
          {link.title}
        </LocalizedLink>
      )
    }
  }
  return (
    <div className="launchpad-links">
      {links.map((link: any, index: number) => (
        <div key={index} className="col-sm-12 d-flex justify-content-center px-layout-3">
          {buttonLink(link)}
        </div>
      ))}
    </div>
  )
}

export default LaunchPadLinks
