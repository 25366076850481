import React from 'react'

import LogoLight from './assets/images/Light.inline.svg'

import './styles.scss'

interface LaunchPadHeroProps {
  title: string;
}

const LaunchPadHero: React.FC<LaunchPadHeroProps> = ({ title }) => {
  return (
    <div className="container hero bg-points">
      <div className="raw">
       <div className="col-md-12 d-flex justify-content-center">
        <LogoLight/>
       </div>
       <div className="col-md-12 py-layout-2">
         <div className="text-center d-flex justify-content-center px-layout-2">
          <p className="text-light main">{title}</p>
         </div>
       </div>
      </div>
    </div>
  )
}

export default LaunchPadHero
