import * as React from 'react';

import { graphql } from 'gatsby';
import IndexLayout from '../layouts/';


import LaunchPadHero from '../components/LaunchPad/LaunchpadHero/LaunchPadHero';
import LaunchPadLinks from '../components/LaunchPad/LaunchpadLinks/LaunchPadLinks';
import LaunchPadhSocialFooter from '../components/LaunchPad/LaunchPadSocialFooter/LaunchPadhSocialFooter';
import SEO from '../components/SEO/SEO';
import { LocaleTypes } from '../data/locales';

interface LaunchpadProps {
  data: {
    launchpadData: {
      title: string;
      launchPad: Array<{
        title: string;
        url: {
          url: string;
        }
        color: string;
      }> | [];
    }
  }
  pathContext: {
    localeCode: LocaleTypes
  }
  location: Location
}

const launchpad: React.FC<LaunchpadProps> = ({ data, pathContext, location}) => {

  const content = () => (
    <>
      <SEO titleId="launchpad.title" />

      <LaunchPadHero title={data.launchpadData.title} />


      <LaunchPadLinks links={data.launchpadData.launchPad}/>

      <LaunchPadhSocialFooter />

    </>
  )
  return <IndexLayout page="launchpad" render={content} location={location} locale={pathContext.localeCode} />
}

export default launchpad

export const query = graphql`
  query($localeCode: String!) {
    launchpadData: contentfulLaunchpad(node_locale: {eq: $localeCode}) {
      title
      slug
      node_locale
      contentful_id
      launchPad {
        url {
          url
        }
        title
        color
      }
    }
  }
`
