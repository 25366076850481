import React from 'react'

import { OutboundLink } from 'gatsby-plugin-google-gtag'

import youtubeImg from './assets/images/social/youtube.svg';
import instagramImg from './assets/images/social/instagram.svg';
import linkedinImg from './assets/images/social/linkedin.svg';
import twitterImg from './assets/images/social/twitter.svg';

import './styles.scss';

const LaunchPadhSocialFooter: React.FC = () => {
  return (
    <footer className="footer px-2 footer--primary">
      <section className="container">
        <div className="row px-layout-3 d-flex align-items-center justify-content-around">
            <OutboundLink href="https://www.youtube.com/channel/UCkSHkfXxlHsDE62kxl0RWQg" className="social-button" rel="noreferrer" target="_blank">
              <img src={youtubeImg} alt="Youtube" />
            </OutboundLink>
            <OutboundLink href="https://www.instagram.com/kushkioficial" className="social-button" rel="noreferrer" target="_blank">
              <img src={instagramImg} alt="Instagram" />
            </OutboundLink>
            <OutboundLink href="https://twitter.com/kushkioficial" className="social-button" rel="noreferrer" target="_blank">
              <img src={twitterImg} alt="Twitter" />
            </OutboundLink>
            <OutboundLink href="https://www.linkedin.com/company/kushki" className="social-button" rel="noreferrer" target="_blank" >
              <img src={linkedinImg} alt="LinkedIn" />
            </OutboundLink>
        </div>
      </section>
    </footer>
  )
}

export default LaunchPadhSocialFooter
